import styled from 'styled-components'
import {
  Media,
  hideSMDown,
  hideSMUp,
  spacing,
  typography,
  transition,
} from '@renderbus/common/theme'
import CouponImg from '../images/xgt-membership-month/coupon.png'
export const BannerContainer = styled.div`
  position: relative;
  height: 600px;
  padding-top: 60px;
  ${Media.lessThan(Media.small)} {
    height: 100vh;
    padding-top: 80px;
    > div:first-child {
      flex-direction: column;
    }
  }
`
export const Title = styled.img`
  ${hideSMDown};
`
export const TitleMb = styled.img`
  ${hideSMUp};
`
export const BannerWrapper = styled.div`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  button {
    background: linear-gradient(0deg, #dfa97b, #f0d8c2);
    color: #000;
    margin-top: 40px;
    font-weight: 500;
    font-size: ${typography.h4};
    transition: ${transition('background')};
    :hover {
      background: linear-gradient(0deg, #925e28, #f0d8c2);
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-right: 0;
    align-items: center;
    img {
      width: 70%;
    }
    button {
      margin-top: ${spacing.base};
    }
    p {
      margin: ${spacing.small};
    }
  }
`
export const Box = styled.img`
  width: 29%;
  margin: -30px 60px 0 0;
  ${Media.lessThan(Media.small)} {
    width: 80%;
    margin: 0 0;
  }
`
export const Wrapper = styled.div`
  background-color: #110f0f;
  padding: 20px 0;
`
export const Section = styled.section`
  border: 2px solid #c8a480;
  width: 1200px;
  position: relative;
  padding: 0 85px;
  margin: 80px auto;
  background-image: -webkit-linear-gradient(
    -90deg,
    rgb(127, 115, 113) 0%,
    rgba(80, 73, 73, 0.5) 0%,
    rgba(32, 30, 33, 0.9) 6%
  );
  ${Media.lessThan(Media.small)} {
    width: 90%;
    padding: 0 25px;
    margin: ${spacing.large} auto;
  }
`
export const Light = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  ${Media.lessThan(Media.small)} {
    width: 50%;
  }
`
export const Badge = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  ${Media.lessThan(Media.small)} {
    width: 20%;
  }
`
export const SectionTitle = styled.h2`
  font-size: 3.75rem;
  background-image: linear-gradient(0deg, #dfa97b 16.40625%, #f0d8c2 83.59375%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: ${spacing.large};
  ${Media.lessThan(Media.small)} {
    font-size: 1.25rem;
  }
`
export const Tip = styled.p`
  text-align: center;
  margin-top: -25px;
  color: #a48669;
  font-size: ${typography.h3};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textThin};
  }
`
export const TipWrapper = styled.div`
  margin: -40px auto 80px auto;
  p {
    margin-top: 0;
    margin-bottom: ${spacing.small};
    color: #a48669;
    font-size: ${typography.h3};
  }
  ${Media.lessThan(Media.small)} {
    margin: -30px auto 25px auto;
    p {
      font-size: ${typography.textSmall};
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
`
export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px auto 110px auto;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    margin: 25px auto ${spacing.large} auto;
  }
`
export const Step = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 22%;
  }
`
export const Description = styled.div`
  margin: 50px auto 100px auto;
  p {
    margin-top: 0;
    margin-bottom: 15px;
    color: #a48669;
    font-size: ${typography.h3};
  }
  ${Media.lessThan(Media.small)} {
    margin: 25px auto ${spacing.large} auto;
    p {
      font-size: ${typography.textSmall};
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
`
export const Coupon = styled.div`
  background: ${`url(${CouponImg})`};
  width: 500px;
  height: 148px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  font-size: ${typography.h2};
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100px;
    font-size: ${typography.h4};
    margin-bottom: 15px;
  }
`
export const Left = styled.p`
  color: #eed2b8 !important;
  margin: 0 47px;
  font-weight: 500;
  width: 108px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 60px;
    font-size: ${typography.h4};
    margin: 0 ${spacing.large};
  }
`
export const Divider = styled.hr`
  width: 1px;
  height: 120px;
  border: 1px dashed #edc9a2;
  margin: 0;
  ${Media.lessThan(Media.small)} {
    height: 72px;
  }
`
export const Right = styled.div`
  margin-left: 47px;
  p {
    background: linear-gradient(0deg, #dfa97b 16.40625%, #f0d8c2 83.59375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
  button {
    font-size: ${typography.h4};
    background: linear-gradient(0deg, #dfa97b, #f0d8c2);
    color: #000;
    margin-top: 0;
    transition: ${transition('background')};
    border-radius: 20px;
    :hover {
      background: linear-gradient(0deg, #925e28, #f0d8c2);
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-left: ${spacing.large};
    button {
      font-size: ${typography.textThin};
    }
  }
`
